<template lang="pug">
  base-page
    template(v-slot:titlu)
      span Angajati
    template(v-slot:leftmenu)
      el-tooltip(content="Adauga un nou angajat" v-if="$has_right('editare-angajati')")
        el-button(type='primary' @click='show_dialog()' icon='el-icon-plus' size='small' circle)
      el-tooltip(content="Import excel")
        el-button( type='warning' @click='show_import_dialog()' icon='el-icon-tickets' size='small' circle)
      el-tooltip(content="Export excel")
        a( class='el-button el-tooltip el-button--success el-button--small is-circle' icon='el-icon-document' type='primary' :href="get_export_excel_url()" target="_blank")
          i( class='el-icon-document' )
    
    el-card
      el-form(label-position="top" :inline="false" label-width="100%"  :model='Filters'  ref='my-form' )
        el-row(:gutter="20")
          el-col(:md='4' :xs='12')
            el-form-item( label="Nume" prop="Nume"  )
              el-input( v-model="Filters.Nume"  size='mini' )
          el-col(:md='4' :xs='12')
            el-form-item( label="Tag" prop="Tag"  )
              el-input( v-model="Filters.Tag"  size='mini' )
          el-col(:md='4' :xs='12')
            el-form-item( label="Departament" prop="IdDepartament"  )
              el-select.full-width( v-model="Filters.IdDepartament"  size='mini' )
                el-option(v-for="d in Info.departamente" :key="d.Id" :label="d.Nume" :value="d.Id")
          el-col(:md='4'  :xs='12')
            el-form-item( label="Status" prop="Status"  )
              el-select.full-width( v-model="Filters.Status"  size='mini' )
                el-option(label="Doar Activi"   value="activi"  )
                el-option(label="Toti"          value="toti"    )
                el-option(label="Doar Inactivi" value="inactivi")
          el-col(:md='8' :xs='24' )
            div.margin50
              el-button(type='success' size='mini' @click="refresh_info") Filtreaza
              el-button(type='danger'  size='mini' @click="reset()") Reset

      

    el-table( :data="Results" height="calc(100vh - 400px)" v-loading='loadingVisible')
      el-table-column( label="Nume" width='300')
        template(slot-scope="prop")
          p(v-if="prop.row.StersDeLaDataDe == null" ) {{prop.row.Nume}}
          el-tag( v-else type="danger") {{prop.row.Nume}}
          el-tag( v-if="prop.row.Sters == '1'" type='danger') Sters manual
            
      el-table-column( prop="Tag" label="Tag"  min-width="200")
      el-table-column( prop="NumeDepartament" label="Departament" min-width="200")
      el-table-column( prop="NumeCentruCost"  label="Centru de cost"  min-width="200")
      el-table-column( prop="NumeProgram"     label="Program"  min-width="200")
      el-table-column( prop="DetaliiRaport"   label="Detalii"  min-width="200")
      el-table-column( prop="StersDeLaDataDe" label="Incetare Contract"  width='200')
      el-table-column( prop="DataIncepereContract" label="Incepere Contract"  width='200')
      
      
      el-table-column( fixed="right" label="Actiuni" width="200px" )
        template( slot-scope="scope" )
          div(v-if="scope.row.Sters=='0'")
            el-tooltip( content="Modificare" v-if="$has_right('editare-angajati')")
              el-button( type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" )
            el-tooltip( content="Inactiveaza" v-if="$has_right('stergere-angajati')")
              el-button( type="danger" size='mini' icon="el-icon-delete" circle @click="delete_item(scope.row)"    )
            el-tooltip( content="Incheiere contract" v-if="scope.row.StersDeLaDataDe == null && $has_right('editare-angajati')")
              el-button( type="warning" size='mini' icon="el-icon-s-check" circle @click="concediaza_angajat(scope.row)"    )
          div(v-else)
            el-tooltip( content="Pune inapoi item sters")
              el-button( type="success" size='mini' icon="el-icon-d-arrow-left" circle @click="undelete_item(scope.row)"    )
            el-tooltip( content="Sterge de tot din baza de date" )
              el-button( type="danger" size='mini' icon="el-icon-delete" circle @click="fulldelete_item(scope.row)"    )

    el-pagination( @size-change= "refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager")
    angajati-dialog( ref='dlg' @save="refresh_info()" )
    import-dialog( ref='importdlg' @refresh="refresh_info()" )
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import AngajatiDialog from '../dialogs/AngajatiDialog';
import ImportAngajatiDialog from '../dialogs/ImportAngajatiDialog';
import TitluPagina from '../widgets/TitluPagina';

export default {
  name: "angajati",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'angajati-dialog': AngajatiDialog,
      'titlu-pagina': TitluPagina,
      'import-dialog': ImportAngajatiDialog
  },
  data () {
    return { 
      Results: [],
      base_url: '',
      
      Info: {
        departamente: []
      },
      Filters: { Nume: '', Tag: '', Status:'activi', IdDepartament: -1 },
      OrderBy: { Column:"", Order:"ascending" },
      PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
    }
  },
  methods: {
    async get_info() {
      var        result = await this.post("angajati/get_info", {} );
      this.Info.departamente = [ {Id: -1, Nume:'Toate'} ].concat( result.Info.departamente );

      this.refresh_info();
    },
    async refresh_info() {
      var response        = await this.post("list-angajati", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
      this.Results        = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item('angajati');
    },
    get_export_excel_url() {
      return this.base_url + 'angajati/export_excel/'  + settings.get_id_firma();
    },
    reset(){
      this.Filters= { Nume: '', Tag: '', Status:'activi', IdDepartament: -1 };
      this.refresh_info();
    },
    async delete_item( item ){
      var confirm =  await this.$confirm(`Sterg ${item.Nume} ?`, 'Warning');
      if( confirm ){
         await this.post("delete-angajati", { id: item.Id } );
         this.refresh_info();
      }
    },
    async concediaza_angajat(item){
      this.$refs.dlg.show_me(item.Id, true);

    },
    
    async fulldelete_item( item ){
      var confirm =  await this.$confirm(`Sterg de tot din toate arhivele  '${item.Nume}' ?`, 'Warning');
      if( confirm ){
         await this.post("angajati/full_delete", { id: item.Id } );
         this.refresh_info();
      }
    },
    async undelete_item( item ){
      var confirm =  await this.$confirm(`Punem inapoi ${item.Nume} ?`, 'Warning');
      if( confirm ){
         await this.post("angajati/undelete", { id: item.Id } );
         this.refresh_info();
      }
    },
    show_import_dialog(){
      this.$refs.importdlg.show_me();
    },
    show_dialog(id){
      this.$refs.dlg.show_me(id);
    }
  },
  mounted(){
    settings.verify_login_and_redirect(this);
    
    this.base_url = settings.get_base_url();
    this.get_info();
  }
};
</script>

<style lang="less" scoped>

  .top50{
    margin-top: 20px;
  }
 
</style>
